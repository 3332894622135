// React
import React, { useCallback, useContext } from "react";
import { useTheme } from "@emotion/react";

// MUI
import { Box, IconButton, InputAdornment, TextField, useMediaQuery } from "@mui/material";
import Stack from "@mui/material/Stack";
import SearchIcon from '@mui/icons-material/Search';
import ReplayIcon from "@mui/icons-material/Replay";
import ClearIcon from '@mui/icons-material/Clear';
import { visuallyHidden } from '@mui/utils';

// Misc
import { Context } from "../context/context";
import FileUploadIconButton from "../components/file-upload-icon-button";
import RecordingIconButton from "../components/recording-icon-button";
import config from "../config.json";

const ChatInput = (props) => {
    const {
        chatInputContext,
        messagesContext,
        welcomeQuestionsContext,
        conversationIdContext,
        previewDocumentContext,
        errorStateContext,
        loadingContext,
        isRecordingContext,
    } = useContext(Context);
    const [ chatInput, setChatInput ] = chatInputContext;
    const [, setConversationId ] = conversationIdContext;
    const [, setMessageList ] = messagesContext;
    const [, setWelcomeQuestions ] = welcomeQuestionsContext;
    const [, setPreviewDocument ] = previewDocumentContext;
    const [, setErrorState ] = errorStateContext;
    const [ loading ] = loadingContext;
    const [ isRecording ] = isRecordingContext;

    const theme = useTheme();
    const tinyDevice = useMediaQuery(theme.breakpoints.down("sm"));

    let isMediaRecorderSupported = false;
    if (navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === 'function' && typeof MediaRecorder === 'function') {
        isMediaRecorderSupported = true;
    }

    const handleResetConversation = () => {
        setPreviewDocument(null);
        setConversationId(null);
        setWelcomeQuestions(config.WELCOME?.QUESTIONS || []);
        setMessageList([]);
        setErrorState({ error: false });
    }

    return(
        <Stack
            direction="row"
            alignItems="center"
            sx={{
                //border: '10px solid black',
                width: '100%'
            }}
            spacing={1}
        >
            <TextField
                disabled={isRecording}
                value={chatInput}
                onChange={useCallback(
                    (ev) => setChatInput(ev.target.value),
                    [setChatInput]
                )}
                fullWidth
                // if you are receiving a "ResizeObserver loop completed with undelivered notifications."
                // runtime error it's because of multiline and this bug: https://github.com/mui/material-ui/issues/39105
                // removing multiline fixes the issue which leads me to believe the bug is upstream in MUI
                multiline
                label="Ställ din fråga"
                type="search"
                sx={{
                    bgcolor: 'background.default',
                }}
                onKeyDown={useCallback(
                    (ev) => {
                        if (ev.key === 'Escape') {
                            setChatInput('');
                        }
                        if (loading) {
                            return;
                        }
                        if (ev.key === 'Enter') {
                            props.onSubmit(ev.target.value);
                        } else {
                            return;
                        }
                        ev.preventDefault();
                    }, [loading, props, setChatInput]
                )}
                InputProps={{
                    endAdornment: (
                        <>
                            {chatInput &&
                                <InputAdornment
                                    position="end"
                                    sx={{marginLeft: 0}}
                                >
                                    <IconButton
                                        onClick={() => setChatInput('')}
                                        color="primary"
                                        disabled={loading}
                                    >
                                        <ClearIcon />
                                        <Box component="span" sx={visuallyHidden}>Rensa</Box>
                                    </IconButton>
                                </InputAdornment>
                            }
                            <InputAdornment
                                position="end"
                                sx={{marginLeft: 0}}
                            >
                            </InputAdornment>
                            <InputAdornment
                                position="end"
                                sx={{marginLeft: 0}}
                            >
                                <IconButton
                                    edge="end"
                                    color="primary"
                                    onClick={() => props.onSubmit(chatInput)}
                                    disabled={loading || !chatInput}
                                >
                                    <SearchIcon />
                                    <Box component="span" sx={visuallyHidden}>Skicka frågan</Box>
                                </IconButton>
                            </InputAdornment>
                        </>
                    ),
                }}
            />
            {(!isRecording && tinyDevice && chatInput.length > 3) ||
                <Stack
                    direction="row"
                    spacing={0.1}
                >
                    {config.SPEECH && isMediaRecorderSupported &&
                        <RecordingIconButton
                            color="primary"
                            disabled={loading}
                        />
                    }
                    {config.UPLOAD &&
                        <FileUploadIconButton disabled={loading} />
                    }
                    <IconButton
                        color="primary"
                        onClick={handleResetConversation}
                        disabled={loading}
                    >
                        <ReplayIcon />
                        <Box component="span" sx={visuallyHidden}>Börja om konversationen</Box>
                    </IconButton>
                </Stack>
            }
        </Stack>
    )
}

export default ChatInput;
