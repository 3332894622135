// React
import React, { useCallback, useContext, useEffect } from "react";

// Misc
import { Context } from "../context/context";
import HeaderLogo from "./header-logo";
import ChatMessages from "../components/chat-messages";
import ChatInput from "../components/chat-input";
import FeedbackDialog from "../components/feedback-dialog";
import useAPI from '../hooks/use-api';
import config from '../config.json';

const ChatArea = (props) => {
    const {
        conversationIdContext,
        chatInputContext,
        errorStateContext,
        loadingContext,
        messagesContext,
        previewDocumentContext,
        welcomeQuestionsContext,
    } = useContext(Context);
    const [ messageList, setMessageList ] = messagesContext;
    const [ errorState, setErrorState ] = errorStateContext;
    const [, setLoading] = loadingContext;
    const [ conversationId, setConversationId ] = conversationIdContext;
    const [ , setChatInput ] = chatInputContext;
    const [ , setWelcomeQuestions ] = welcomeQuestionsContext;
    const [ , setPreviewDocument ] = previewDocumentContext;

    const { api } = useAPI();

    const addOrReplacePendingMessage = useCallback((message, forceAdd = true) => {
        setMessageList((prevMessageList) => {
            if (!forceAdd && prevMessageList[prevMessageList.length - 1]?.pending) { // replace the last message, if it's pending (aka <TypingIndicator />) ...
                return prevMessageList.map((item, index) => {
                    if (index === prevMessageList.length - 1) {
                        return message;
                    }
                    return item;
                })
            } else { // ... or append a new one, if it isn't
                return [ ...prevMessageList, message ];
            }
            // unreachable
        })
    }, [setMessageList])

    const addUserMessage = useCallback((message) => {
        return addOrReplacePendingMessage({ ...message, role: 'user' });
    }, [addOrReplacePendingMessage])

    const addAgentMessage = useCallback((message) => {
        return addOrReplacePendingMessage({ ...message, role: 'assistant' }, false);
    }, [addOrReplacePendingMessage])

    const sendMessageAndUpdateChat = async (input = null) => {
        const getHistoryFromMessageList = (messageList) => {
            return messageList.map((message) => {
                return {
                    role:    message.role,
                    content: message.content,
                    source:  message.source,
                }
            })
        }

        if (!input || input === '') {
            return;
        }

        setLoading(true);
        setPreviewDocument(null);
        setWelcomeQuestions([]);
        setChatInput('');

        // create the user message
        addUserMessage({ content: input });

        // create a dummy agent message with typing indicator before fetching the actual reply
        addAgentMessage({ content: '...', source: 'misc', pending: true });

        let response;
        let agentMessage = {
            role : 'assistant',
            reaction: 0,
            comment: '',
            enableFeedback: false,
        }

        try {
            response = await api.post('/chat', {
                conversation_id: conversationId,
                approach: config.APPROACH,
                history: [ ...getHistoryFromMessageList(messageList), { role: 'user', content: input } ],
            });

            agentMessage = {
                ...agentMessage,
                enableFeedback: true,
                content:        response.data?.answer          || "",
                data_points:    response.data?.data_points     || [],
                conversationId: response.data?.conversation_id || -1,
                thoughts:       response.data?.thoughts        || [],
                citations:      response.data?.citations       || [],
            }

            setConversationId(response.data?.conversation_id);

            // update dummy agent message with actual response from backend
            addAgentMessage({ ...agentMessage, source: 'model' });

            setErrorState({ error: false });
        }
        catch (error) {
            console.error('Failed to talk to agent. Reason was: ' + error);
            setErrorState({ error: true, message: error });
            agentMessage = {
                ...agentMessage,
                content: error,
            }
        }

        setLoading(false);
    }

    useEffect(() => {
        if (!errorState.error) {
            return;
        }
        if (!(errorState.message && errorState.message !== '')) {
            return;
        }

        addAgentMessage({ source: 'misc', content: errorState.message });
    }, [errorState, addAgentMessage])

    return (
        <>
            <div
                id="chat-header"
                style={{
                    //border: "10px solid black",
                    padding: "10px",
                }}
            >
                <HeaderLogo />
            </div>
            <div
                id="chat-messages"
                style={{
                    //border: "10px solid orange",
                    height: "100%",
                    padding: "10px",
                    overflow: "auto",
                }}
            >
                <ChatMessages error={errorState.error} onClickWelcomeQuestion={sendMessageAndUpdateChat} />
            </div>
            {/*
            <div
                id="chat-footer"
                style={{
                    _border: "10px solid yellow",
                    padding: "10px",
                }}
            >
                CHAT FOOTER
            </div>
            */}
            <div
                id="chat-input"
                style={{
                    //border: "10px solid green",
                    display: "flex", flexShrink: 0,
                    padding: "10px",
                    alignItems: "center",
                }}
            >
                <ChatInput onSubmit={sendMessageAndUpdateChat} />
            </div>

            <FeedbackDialog />
        </>
    )
}

export default ChatArea;