// React
import React, { useContext, useEffect } from "react";

// MUI
import { useTheme } from "@emotion/react";
import { Box, IconButton, useMediaQuery } from "@mui/material";
import Stack from "@mui/material/Stack";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { visuallyHidden } from "@mui/utils";

// Misc
import { Context } from "../context/context";
import VotingButtons from "./voting-buttons";
import useAPI from '../hooks/use-api';

const MessageFeedback = (props) => {
    const {
        messagesContext,
        openFeedbackDialogContext,
        conversationIdContext,
        loadingContext,
        pendingFeedbackContext,
    } = useContext(Context);
    const [ loading ] = loadingContext;
    const [ messageList, setMessageList ] = messagesContext;
    const [, setOpenFeedbackDialog ] = openFeedbackDialogContext;
    const [ conversationId ] = conversationIdContext;
    const [ pendingFeedback, setPendingFeedback ] = pendingFeedbackContext;

    const { api } = useAPI();

    const theme = useTheme();
    const iconDirection =
        useMediaQuery(theme.breakpoints.only("xs"))
            ? "column"
            : "row"
    ;

    const submitVote = async (props, vote) => {
        if (loading) {
            return;
        }

        const newVote = props.reaction === vote ? 0 : vote;
        const newMessageList = messageList.map((obj, i) => {
            if (i === props.messageIndex) {
                return { ...obj, reaction: newVote, comment: newVote === 0 ? "" : obj.comment };
            }
            return obj;
        });

        try {
            await api.post('/reaction', {
                "conversation_id": conversationId,
                "history": newMessageList,
            });
        } catch (error) {
            console.error('Failed to send reaction. Reason: ' + error);
            return;
        }

        setMessageList(newMessageList);
        setPendingFeedback({ ...pendingFeedback, vote: newVote, messageIndex: props.messageIndex }); // new
        if (newVote !== 0) {
            setOpenFeedbackDialog(true);
        }
    }

    useEffect(() => {
        // noop
    }, [pendingFeedback])

    return (
        <Stack
            direction={iconDirection}
            spacing={0.1}
        >
            <IconButton
                onClick={() => {
                    navigator.clipboard.writeText(messageList[props.messageIndex]?.content);
                }}
                sx={{
                    color: 'text.secondary',
                    '&:hover': {
                        color: theme.palette.primary.main,
                    }
                }}
            >
                <ContentCopyIcon fontSize="small" />
                <Box component="span" sx={visuallyHidden}>Kopiera till urklipp</Box>
            </IconButton>
            <VotingButtons
                disabled={props.disabled}
                reaction={messageList[props.messageIndex]?.reaction}
                messageIndex={props.messageIndex}
                onClick={submitVote}
            />
            {messageList[props.messageIndex]?.comment &&
                <IconButton
                    disabled={loading}
                    color="primary"
                    disableRipple
                >
                    <MailOutlineIcon fontSize="small" />
                </IconButton>
            }
        </Stack>
    )
}

export default MessageFeedback;
