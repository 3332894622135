// React
import React from "react";
import { useTheme } from "@emotion/react";

// MUI
import { Box, Button, Paper, Stack } from "@mui/material";

// MSAL
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

// Misc
import config from "../config.json";

const LoginPage = () => {
    const theme = useTheme();
    const { instance, inProgress } = useMsal();

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            await instance.loginRedirect();
            // login successful
        } catch (error) {
            console.log("login error or user cancelled login");
        }
    }

    return (
        <>
            {inProgress === InteractionStatus.None &&
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                >
                    <Paper
                        sx={{
                            width: '400px',
                            padding: theme.spacing(4),
                            margin: '10px',
                            textAlign: 'center',
                            bgcolor: 'background.default',
                        }}
                    >
                        <Stack
                            spacing={4}
                        >
                            <Box
                                width="100%"
                                component="img"
                                alt="Logo"
                                src={config.LOGO_URL}
                            />
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={handleLogin}
                                size="large"
                            >
                                Log in
                            </Button>
                        </Stack>
                    </Paper>
                </Box>
            }
        </>
    )
}

export default LoginPage;
