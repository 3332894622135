import { useSyncExternalStore } from 'react';

const getSnapshot = () => {
    return navigator.language;
}

const subscribe = (callback) => {
    window.addEventListener('languagechange', callback);
    return () => {
        window.removeEventListener('languagechange', callback);
    };
}

const useLanguage = (restrictLanguages) => {
    const browserLanguage = useSyncExternalStore(subscribe, getSnapshot).split('-')[0];
    let language = browserLanguage;

    if (restrictLanguages && !restrictLanguages?.includes(language)) {
        language = 'en';
    }

    return { language };
}

export default useLanguage;
