// React
import { useContext, useEffect, useRef } from "react";

// MUI
import {
    Box,
    Button,
    List,
    ListItem,
    ListItemText,
    useMediaQuery,
} from "@mui/material";
// import WarningIcon from "@mui/icons-material/Warning";
import { useTheme } from '@mui/material/styles';
// import { visuallyHidden } from "@mui/utils";

// Misc
import { Context } from "../context/context";
import ChatMessage from "./chat-message";
import config from '../config.json'

const ChatMessages = (props) => {
    const {
        messagesContext,
        loadingContext,
        welcomeQuestionsContext
    } = useContext(Context);
    const [ messageList ] = messagesContext;
    const [ loading ] = loadingContext;
    const [ welcomeQuestions ] = welcomeQuestionsContext;
    const bottomRef = useRef(null);

    const theme= useTheme();

    const isMobile= useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        scrollToBottom();
    }, [messageList]);

    const scrollToBottom = () => {
        if (bottomRef.current) {
            if (typeof bottomRef.current.scrollIntoViewIfNeeded === 'function') {
                bottomRef.current.scrollIntoViewIfNeeded({ behavior: 'auto' });
            } else {
                bottomRef.current.scrollIntoView({ behavior: 'auto' });
            }
        }
    };

    return (
        <Box>
            <List>
                {config.WELCOME?.AGENT?.map((message, index) => (
                    <ListItem key={index} sx={{ padding: 0 }}>
                        <ListItemText sx={{ margin: 0 }}>
                            <ChatMessage
                                index={index}
                                align="agent"
                                message={message}
                                error={props.error}
                                avatar={!isMobile && (config.SHOW_AVATARS === undefined || !!config.SHOW_AVATARS)}
                                enablefeedback={false}
                            />
                        </ListItemText>
                    </ListItem>
                ))}
                {welcomeQuestions.map((question, index) => (
                    <ListItem
                        sx={{ paddingRight: 0 }}
                        key={index}
                    >
                        <ListItemText
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}>
                            <Button
                                variant="contained"
                                size="large"
                                sx={{
                                    textTransform: 'none',
                                    fontSize: '1rem',
                                    fontWeight: 400,
                                }}
                                onClick={() => {props.onClickWelcomeQuestion(question)}}
                            >
                                {index + 1}. {question}
                            </Button>
                        </ListItemText>
                    </ListItem>
                ))}
                {messageList?.map((message, index) => (
                    <ListItem key={index} sx={{ padding: 0 }}>
                        <ListItemText sx={{ margin: 0 }}>
                            <ChatMessage
                                index={index}
                                length={messageList.length}
                                align={message.role}
                                message={message.content}
                                typing={message.pending}
                                citations={message.citations}
                                error={props.error}
                                avatar={!isMobile && (config.SHOW_AVATARS === undefined || !!config.SHOW_AVATARS)}
                                enableFeedback={message.enableFeedback && config.REACTIONS && !((index + 1 === messageList.length) && loading)}
                            />
                        </ListItemText>
                    </ListItem>
                ))}
                {/* !loading && props.error &&
                    <>
                        <WarningIcon color='error' />
                        <Box component="span" sx={visuallyHidden}>Något gick fel</Box>
                    </>
                */}
                <div ref={bottomRef} />
            </List>
        </Box>
    )
}

export default ChatMessages;
