// React
import React from "react";
import { Box, Grid } from "@mui/material";

// Misc
import config from "../config.json";

const HeaderLogo = () => {
    return (
        <Grid
            container
        >
            <Box
                sx={{
                    maxWidth: "20%",
                    minWidth: "100px",
                }}
                component="img"
                alt="Logo"
                src={config.LOGO_URL}
            />
        </Grid>
    )
}

export default HeaderLogo;
