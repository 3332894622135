// React
import React, { useContext } from "react";
import { useTheme } from "@emotion/react";

// MUI
import { Box, useMediaQuery } from "@mui/material";

// MSAL
import { useIsAuthenticated } from "@azure/msal-react";

// Misc
import { useWindowSize } from 'usehooks-ts'
import { Context } from "../context/context";
import LoginPage from "./login-page";
import MainFooter from "./main-footer";
import ChatArea from "./chat-area";
import DocumentArea from "./document-area";
import config from "../config.json";

const Main = () => {
    const { previewDocumentContext } = useContext(Context);
    const [ previewDocument ] = previewDocumentContext;

    // MSAL
    const enableMSAL = !!config.MSAL?.auth?.clientId;
    const isAuthenticated = useIsAuthenticated();

    const { height = 0 } = useWindowSize();
    const theme = useTheme();

    const showPreview = previewDocument;
    const smallDevice = useMediaQuery(theme.breakpoints.down("lg"));
    const tinyDevice = useMediaQuery(theme.breakpoints.down("sm"))
    const showChat = !showPreview || (showPreview && !smallDevice);
    const containerWidth = showChat && showPreview ? "50%" : "100%";
    const showFooter = config.FOOTER && showChat && !tinyDevice;

    return (
        <Box // use Box component instead of plain div so that the breakpoints below work
            sx={{
                display: "flex",
                justifyContent: "center",
                height: height && height > 0 ? height + 'px' : '100vh',
                [theme.breakpoints.only('xs')]: {
                    padding: "5px",
                },
                [theme.breakpoints.up('xs')]: {
                    padding: "10px",
                },
                backgroundColor: 'background.outer',
            }}
        >
            <Box // use Box component instead of plain div so that the breakpoints below work
                id="main-container"
                sx={{
                    //border: "10px solid grey",
                    borderRadius: "10px",
                    display: "flex", flexDirection: "column",
                    backgroundColor: 'background.default',
                    [theme.breakpoints.only('xs')]: {
                        padding: "0px",
                    },
                    [theme.breakpoints.up('xs')]: {
                        padding: "10px",
                    },
                    height: "100%",
                    [theme.breakpoints.down('lg')]: {
                        width: "100%",
                    },
                    [theme.breakpoints.up('lg')]: {
                        width: showPreview ? "90%" : "70%",
                    },
                }}
            >
                {enableMSAL && !isAuthenticated
                    ?
                        <LoginPage />
                    :
                        <>
                            {/* showChat &&
                                <div
                                    id="main-header"
                                    style={{
                                        _border: "10px solid black",
                                        padding: "10px",
                                    }}
                                >
                                    MAIN HEADER
                                </div>
                            */}
                            <div
                                id="content-container"
                                style={{
                                    display: "flex", flexDirection: "row",
                                    height: "100%", minHeight: 0,
                                }}
                            >
                                {showChat &&
                                    <div
                                        id="chat-container"
                                        style={{
                                            //border: "10px solid lightgreen",
                                            height: "100%", width: containerWidth,
                                            display: "flex", flexDirection: "column",
                                        }}
                                    >
                                        <ChatArea />
                                    </div>
                                }
                                {showPreview &&
                                    <div
                                        id="preview-container"
                                        style={{
                                            //border: "10px solid lightgreen",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            width: containerWidth,
                                        }}
                                    >
                                        <DocumentArea />
                                    </div>
                                }
                            </div>
                            {showFooter && showChat &&
                                <div
                                    id="main-footer"
                                    style={{
                                        //border: "10px solid black",
                                        padding: "10px",
                                    }}
                                >
                                    <MainFooter />
                                </div>
                            }
                        </>
                }
            </Box>
        </Box>
    )
}

export default Main;
