// React
import React from "react";
import { Typography } from "@mui/material";

// Misc
import config from "../config.json";

const MainFooter = () => {
    return (
        <Typography
            variant="footer"
            gutterBottom
            dangerouslySetInnerHTML={{ __html: config.FOOTER }} // eval FOOTER as HTML so that it's fully customizable from the config
        />
    )
}

export default MainFooter;
